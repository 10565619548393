@keyframes openingMenu {
    from {
        height: 100px;
        backdrop-filter: blur(0);
    }
    to {
        height: calc(100vh - 34px);
        background-color: rgba(255, 255, 255, 0.219);
        backdrop-filter: blur(17px);
    }
}

@keyframes openingSocialLinks {
    from {
        transform: translateX(250px);
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes waves {
    to {
        transform: translateY(-5px)
    }
}

@keyframes openingNavLinks {
    to {
        opacity: 1;
    }
}

.menu {
    position: fixed;
    z-index: 9;
    width: calc(100vw - 34px);
    top: 17px;
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 25px;
    box-sizing: border-box;
}

.menu_opened {
    animation: openingMenu .38s ease-out forwards;
}

.menu__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu__button-wrapper::before {
    content: '';
    height: 35px;
    width: 2px;
    margin-left: 25px;
    margin-right: 15px;
    background-color: #8e9aaaa8;
    order: 2;
}

.menu__logo {
    width: 50px;
}

.menu__open-button {
    background-color: transparent;
    border: none;
    padding:  5px 0 5px;
    width: 75px;
    font-family: ubuntu;
    letter-spacing: 2px;
    transition: letter-spacing linear .1s;
    cursor: pointer;
    order: 3;
}

.menu__open-button:hover {
    letter-spacing: 3px;
}

.menu__social-links {
    display: none;
    padding-left: 0;
    margin: 70px 0 50px;
    padding-left: 95px;
    position: relative;
}

.menu__social-links_opened {
    display: block;
}

.menu__social-links::before {
    content: '';
    width: 70px;
    height: 3px;
    background-color: #3f3f3f;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}


.menu__social-links li {
    position: relative;
    display: inline-block;
    margin-right: 30px;
    opacity: 0;
    animation: openingSocialLinks .5s ease-out .2s forwards;
}

.menu__social-links li:hover img {
    animation: waves .4s linear infinite alternate;
}

.menu__nav {
    height: 100%;
    max-height: 450px;
}

.menu__nav-links {
    display: none;
    height: 100%;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    opacity: 0;
}

.menu__nav-links_opened {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: openingNavLinks .7s .5s linear forwards;
}

.menu__link {
    display: inline-block;
    width: 364px;
    font-family: IntroCondensed, -apple-system, sans-serif;
    font-size: 3.7rem;
    font-weight: bold;
    transition: transform .1s linear;
}

.menu__link:hover {
    transform: scale(1.03);
    opacity: .5;
}

.menu__link-description {
    margin-left: 30px;
}

.menu__send-email {
    margin-left: auto;
    line-height: 30px;
    font-size: 13px;
    order: 4;
}

.menu__send-email img {
    margin-left: 10px;
    vertical-align: middle;
}

.menu__send-email:hover::before {
    content: 'Написать на почту';
}

@media (max-width: 750px) {
    .menu {
        padding: 10px;
    }

    .menu__nav-links {
        max-width: 350px;
    }

    .menu__link {
        font-size: 2.5rem;
    }

    .menu__link-description {
        margin-left: 0;
    }
}
@import url('./fonts/fonts.css');

:root {
  --main-background-color: #d2e0fd;
  --gradient-color-one: #8779d4;
  --gradient-color-two: #2d74d5;
  --gradient-color-one-alpha: #8779d4c0;
  --gradient-color-two-alpha: #2d74d5c0;
  --second-header-font-size: 3rem;
  --thrid-header-font-size: 2.5rem;
  --regular-font-size: 18px;
}

#root {
  background-color: var(--main-background-color);
}

body {
  margin: 0;
  padding: 17px;
  font-family: Ubuntu, -apple-system, sans-serif;
}

body::before {
  content: '';
  width: 100%;
  background-color: white;
  height: 17px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

body::after {
  content: '';
  width: 100%;
  background-color: white;
  height: 17px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}

:link {
  text-decoration: none;
  color: rgb(67, 67, 68);
}

:visited {
  color: rgb(67, 67, 68);
}

.active {
  text-decoration: underline;
}

.home {
    padding: 100px 50px;
}

.home__intro {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 0;
}

.home__intro::before {
    content: '';
    position: absolute;
    right: 0;
    z-index: 0;
    background: radial-gradient(circle, rgba(104,163,252,1) 30%, rgba(0,0,0,0) 30%);
    background-size: 10px 10px;
    width: 50vw;
    height: 45%;
}

.home__intro-title {
    display: inline;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 4rem;
    font-weight: normal;
    white-space: nowrap;
    background: linear-gradient( 109.6deg, var(--gradient-color-one) 11.2%, var(--gradient-color-two) 91.2% );
}

.home__intro-description {
    font-size: var(--regular-font-size);
    margin: 7px 0;
}

.home__intro-photo {
    position: relative;
    width: 30%;
    z-index: 1;
    margin: 0 auto;
    border-radius: 17px;
}

.home__greeting {
    width: 50%;
    margin-top: 70px;
    margin-right: auto;
    padding: 20px;
    padding-left: 0;
}

.home__greeting-header {
    display: inline;
    font-size: var(--second-header-font-size);
    background: linear-gradient( 109deg, var(--gradient-color-one) 11.2%, var(--gradient-color-two) 91.2% );
}

.home__greeting-description {
    font-size: var(--regular-font-size);
}

.home__greeting hr {
    width: 37%;
    height: 3px;
    margin: 15px auto 5px 0;
    margin-right: auto;
    background: #3f3f3f;
}

.home__links-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    text-align: center;
    line-height: 50px;
}

.home__link {
    color: #000;
    vertical-align: middle;
    width: 150px;
    height: 50px;
    margin: 7px;
    border: none;
    border-radius: 15px;
    transition: 0.5s;
    background: linear-gradient(90deg, var(--gradient-color-two) 0%, var(--gradient-color-one)  51%, var(--gradient-color-two) 100%);
    background-size: 300% auto;
}

.home__link:hover {
    background-position: right center;
}

@media (max-width: 1000px) {
    .home__intro-title {
        font-size: 8vw;
    }

    .home__intro-photo {
        width: 50%;
    }

    .home__intro {
        flex-direction: column-reverse;
        padding: 0;
    }

    .home__intro::before {
        top: 10%;
        left: 0;
        width: 100%;
    }

    .home__intro-decoration {
        transform: none;
    }
}

@media (max-width: 700px){
    .home__greeting {
        width: auto;
    }

    .home__greeting-header {
        font-size: 10vw;
    }

    .home__links-wrapper {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 450px) {
    .home__intro-photo {
        width: 80%;
    }
}
.notebook {
    position: relative;
    overflow: hidden;
    padding-bottom: 55.36%;
}

.notebook__control-buttons {
    padding: 0;
    font-size: 3vw;
}

.notebook__control-buttons-item {
    display: inline;
    margin: 0 5%;
    cursor: pointer;
    background-color: #ffffff80;
}

.notebook__content-window {
    position: absolute;
    top: 13.7%;
    right: 5.5%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 59%;
    height: 64%;
    padding-top: 3%;
    overflow-y: scroll;
    color: white;
    z-index: 1;
}

.notebook__coverPicture {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}

@media (max-width: 550px) {
    .notebook {
        padding-bottom: 79.27%;        
    }

    .notebook__control-buttons {
        margin: 0;
    }

    .notebook__control-buttons-item {
        font-size: 5vw;
    }
    
    .notebook__coverPicture {
        width: auto;
        height: 100%;
    }

    .notebook__content-window {
        right: 8%;
        width: 85%;
        height: 66%;
    }
}